// import * as LayoutPage from "scenes/Layout/Layout";
// import * as MainPage from "scenes/MainPage/MainPage";
// import * as ErrorPage from "scenes/ErrorPage/ErrorPage";
// import * as ImageDetectionPage from "scenes/ImageDetectionPage/ImageDetectionPage";
// import * as MapPage from "scenes/MapPage/MapPage";

// const features = {};

// const CALL_DISABLED_FEATURE = "equipenine/CALL_DISABLE_FEATURE";

// const overrideValues = (object, newValue) => {
//   const newObject = {};
//   Object.keys(object).forEach((key) => {
//     newObject[key] = newValue;
//   });
//   return newObject;
// };

// const registerFeature = ({
//   name,
//   enabled,
//   reducer,
//   actions,
//   types,
//   selectors,
//   pages,
//   ...rest
// }) => {
//   if (!name) {
//     throw new Error("Feature name is missing");
//   }
//   if (name in features) {
//     throw new Error(`Feature with name ${name} already exists`);
//   }

//   if (enabled) {
//     features[name] = {
//       enabled,
//       reducer,
//       actions,
//       types,
//       selectors,
//       pages,
//       ...rest
//     };
//   } else {
//     // Override feature stuff to not do everything if it is not enabled
//     const feature = {
//       enabled,
//       // selectors always have to be public cause of failures in isPending
//       selectors
//     };
//     if (reducer && typeof reducer === "function") {
//       feature.reducer = () => ({});
//     }
//     if (actions && typeof actions === "object") {
//       feature.actions = overrideValues(actions, () => {
//         console.info(
//           `Action can not be executed cause feature ${name} is disabled`
//         );
//         return {
//           type: CALL_DISABLED_FEATURE
//         };
//       });
//     }
//     if (pages && typeof pages === "object") {
//       feature.pages = overrideValues(pages, {});
//     }
//     features[name] = feature;
//   }
// };

// registerFeature({
//   name: "main",
//   enabled: true,
//   ...MainPage
// });

// registerFeature({
//   name: "error",
//   enabled: true,
//   ...ErrorPage
// });

// registerFeature({
//   name: "layout",
//   enabled: true,
//   ...LayoutPage
// });

// registerFeature({
//   name: "imageDetection",
//   enabled: true,
//   ...ImageDetectionPage
// });

// registerFeature({
//   name: "map",
//   enabled: true,
//   ...MapPage
// });

// export default features;
///////////////////////////
// import * as LayoutPage from "scenes/Layout/Layout";
// import * as MainPage from "scenes/MainPage/MainPage";
// import * as ErrorPage from "scenes/ErrorPage/ErrorPage";
// import * as ImageDetectionPage from "scenes/ImageDetectionPage/ImageDetectionPage";
// import * as MapPage from "scenes/MapPage/MapPage";
// import { Reducer, PayloadAction } from '@reduxjs/toolkit';

// interface Feature {
//   enabled: boolean;
//   reducer?: Reducer<any>;
//   actions?: Record<string, () => PayloadAction<any>>;
//   selectors?: Record<string, unknown>;
//   pages?: Record<string, unknown>;
//   [key: string]: any;
// }

// const features: Record<string, Feature> = {};

// const CALL_DISABLED_FEATURE = "equipenine/CALL_DISABLE_FEATURE";

// const overrideValues = <T>(object: Record<string, T>, overrideFunction: (key: string) => T): Record<string, T> => {
//   const newObject: Record<string, T> = {};
//   Object.keys(object).forEach((key) => {
//     newObject[key] = overrideFunction(key);
//   });
//   return newObject;
// };

// const registerFeature = ({
//   name,
//   enabled,
//   reducer,
//   actions,
//   types,
//   selectors,
//   pages,
//   ...rest
// }: Feature & { name: string }): void => {
//   if (!name) {
//     throw new Error("Feature name is missing");
//   }
//   if (name in features) {
//     throw new Error(`Feature with name ${name} already exists`);
//   }

//   if (enabled) {
//     features[name] = {
//       enabled,
//       reducer,
//       actions,
//       types,
//       selectors,
//       pages,
//       ...rest
//     };
//   } else {
//     const feature: Feature = {
//       enabled,
//       selectors
//     };
//     if (reducer && typeof reducer === "function") {
//       feature.reducer = () => ({});
//     }
//     if (actions && typeof actions === "object") {
//       // Приклад використання для actions:
// feature.actions = overrideValues(actions, (key) => () => {
//   console.info(`Action ${key} can not be executed because feature ${name} is disabled`);
//   return { type: CALL_DISABLED_FEATURE, payload: null };
// });

//     }
//     if (pages && typeof pages === "object") {
// // Приклад використання для pages:
// feature.pages = overrideValues(pages, (key) => {
//   return () => {
//     console.info(`Page ${key} can not be rendered because feature ${name} is disabled`);
//     // Повернення порожнього компонента або null, залежно від вашої логіки рендерингу
//     return null;
//   };
// });
//     }
//     features[name] = feature;
//   }
// };

// registerFeature({
//   name: "main",
//   enabled: true,
//   ...MainPage
// });

// registerFeature({
//   name: "error",
//   enabled: true,
//   ...ErrorPage
// });

// registerFeature({
//   name: "layout",
//   enabled: true,
//   ...LayoutPage
// });

// registerFeature({
//   name: "imageDetection",
//   enabled: true,
//   ...ImageDetectionPage
// });

// registerFeature({
//   name: "map",
//   enabled: true,
//   ...MapPage
// });

// export default features;

import * as LayoutPage from "scenes/Layout/Layout";
import * as MainPage from "scenes/MainPage/MainPage";
import * as ErrorPage from "scenes/ErrorPage/ErrorPage";
import * as ImageDetectionPage from "scenes/ImageDetectionPage/ImageDetectionPage";
import * as MapPage from "scenes/MapPage/MapPage";
import * as AuthPage from "scenes/AuthPage/AuthPage";
import * as OrthophotoPlanningPage from "scenes/OrthophotoPlanningPage/OrthophotoPlanningPage";
import * as Modal from "scenes/Modal/Modal";

interface Feature {
  enabled: boolean;
  reducer?: any;
  actions?: any;
  selectors?: any;
  pages?: any;
  [key: string]: any;
}

const features: Record<string, Feature> = {};

const CALL_DISABLED_FEATURE = "equipenine/CALL_DISABLE_FEATURE";

const overrideValues = <T>(
  object: Record<string, T>,
  overrideFunction: (key: string) => T
): Record<string, T> => {
  const newObject: Record<string, T> = {};
  Object.keys(object).forEach((key) => {
    newObject[key] = overrideFunction(key);
  });
  return newObject;
};

export const registerFeature = ({
  name,
  enabled,
  reducer,
  actions,
  types,
  selectors,
  pages,
  ...rest
}: Feature & { name: string }): void => {
  if (!name) {
    throw new Error("Feature name is missing");
  }
  if (name in features) {
    throw new Error(`Feature with name ${name} already exists`);
  }

  if (enabled) {
    features[name] = {
      enabled,
      reducer,
      actions,
      types,
      selectors,
      pages,
      ...rest
    };
  } else {
    const feature: Feature = {
      enabled,
      selectors
    };
    if (reducer && typeof reducer === "function") {
      feature.reducer = () => ({});
    }
    if (actions && typeof actions === "object") {
      feature.actions = overrideValues(actions, (key) => () => {
        console.info(
          `Action ${key} can not be executed because feature ${name} is disabled`
        );
        return { type: CALL_DISABLED_FEATURE };
      });
    }
    if (pages && typeof pages === "object") {
      feature.pages = overrideValues(pages, (key) => {
        console.info(
          `Page ${key} can not be rendered because feature ${name} is disabled`
        );
        return () => null;
      });
    }
    features[name] = feature;
  }
};

registerFeature({
  name: "main",
  enabled: true,
  ...MainPage
});

registerFeature({
  name: "error",
  enabled: true,
  ...ErrorPage
});

registerFeature({
  name: "layout",
  enabled: true,
  ...LayoutPage
});

registerFeature({
  name: "imageDetection",
  enabled: true,
  ...ImageDetectionPage
});

registerFeature({
  name: "map",
  enabled: true,
  ...MapPage
});

registerFeature({
  name: "orthophotoPlanning",
  enabled: true,
  ...OrthophotoPlanningPage
});

registerFeature({
  name: "auth",
  enabled: true,
  ...AuthPage
});

registerFeature({
  name: "modal",
  enabled: true,
  ...Modal
});

export default features;
