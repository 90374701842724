import classNames from "classnames";
import { useState } from "react";

import MapLayer from "../MapLayer/MapLayer";

import layersIcon from "assets/icons/layers.svg";
import expandedLayersIcon from "assets/icons/expanded-layers.svg";

import styles from "./map-layers.module.scss";

const MapLayers = ({ layers, aries }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpenState = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div
      className={classNames(styles["layers"], {
        [styles["layers--active"]]: isOpen
      })}
    >
      <div
        className={classNames(styles["layers__button"], {
          [styles["layers__button-active"]]: isOpen
        })}
      >
        <div
          className={classNames(styles["layers__button-small-container"], {
            [styles["layers__button-small-container-active"]]: isOpen
          })}
          onClick={handleToggleOpenState}
        >
          <span className={styles["layers__button-label"]}>Layers</span>
          <div
            className={classNames(
              styles["icon-container"],
              styles["layers-icon"],
              {
                [styles["layers-icon__inactive"]]: isOpen
              }
            )}
          >
            <img src={layersIcon} alt="layers-button" />
          </div>
          <div
            className={classNames(
              styles["icon-container"],
              styles["layers-expanded-icon"],
              {
                [styles["layers-expanded-icon__active"]]: isOpen
              }
            )}
          >
            <img src={expandedLayersIcon} alt="layers-button" />
          </div>
        </div>
        <div className={styles["layers-wrapper"]}>
          <div className={styles["layers-content"]}>
            <div className={styles["layers__head"]}>
              <div className={styles["layers__title"]}>Sensors</div>
            </div>
            <div className={styles["layers__list"]}>
              {layers.map((layer, index) => (
                <MapLayer {...layer} key={`layer-${index}`} />
              ))}
            </div>
            <div className={styles["layers__head"]}>
              <div className={styles["layers__title"]}>Aires</div>
            </div>
            <div className={styles["layers__list"]}>
              {aries.map((layer, index) => (
                <MapLayer {...layer} key={`layer-${index}`} isPolygon />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MapLayers;
