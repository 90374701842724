import BlocksList from "../components/BlocksList/BlocksList";
import ProjectsList from "../components/ProjectsList/ProjectsList";

import styles from "./main-page.module.scss";

const MainPage = () => {
  return (
    <div className={styles["main-page"]}>
      <ProjectsList />
      <BlocksList />
    </div>
  );
};

export default MainPage;
