import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import features from "features";
import { MarkerDetailsInterface, MarkerInterface } from "scenes/MapPage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Slider from "components/Slider/Slider";
import SecondaryButton from "components/buttons/Secondary/Secondary";
import MapMarkerIcon from "../MapMarkerIcon/MapMarkerIcon";
import MapPieMarkerIcon from "../MapPieMarkerIcon/MapPieMarkerIcon";

import closeIcon from "assets/icons/close.svg";
import messageCircleIcon from "assets/icons/message-circle.svg";
import startIcon from "assets/icons/star.svg";
import trashIcon from "assets/icons/trash.svg";

import styles from "./map-marker-details.module.scss";

interface MapMarkerDetailsPropsInterface {
  isLoadingMarkerDetails: boolean;
  markerDetails?: MarkerDetailsInterface;
  activeMarker?: MarkerInterface;
}

const MapMarkerDetails = ({
  markerDetails,
  isLoadingMarkerDetails,
  activeMarker
}: MapMarkerDetailsPropsInterface) => {
  const dispatch = useDispatch();

  const onCloseMapMarkersDetails = useCallback(() => {
    dispatch(features.map.actions.clearMarkerDetails());
  }, []);

  return (
    <div
      className={classNames(styles["details"], {
        [styles["details--active"]]: !isEmpty(markerDetails)
      })}
    >
      <div className={styles["details-wrapper"]}>
        <PreloaderWrapper
          loading={isLoadingMarkerDetails}
          isAbsolute={!isEmpty(markerDetails)}
        >
          <div className={styles["details-content"]}>
            <div
              className={styles["details__close"]}
              onClick={onCloseMapMarkersDetails}
            >
              <img src={closeIcon} alt="close-details"></img>
            </div>
            <div className={styles["details__head"]}>
              {activeMarker && (
                <div className={styles["head__marker"]}>
                  {activeMarker?.layer === "All" ? (
                    <MapPieMarkerIcon
                      color={activeMarker?.color}
                      probability={activeMarker?.probability}
                    />
                  ) : (
                    <MapMarkerIcon
                      color={activeMarker?.color}
                      probability={activeMarker?.probability}
                    />
                  )}
                </div>
              )}
              <div className={styles["head__content"]}>
                <div className={styles["head__title"]}>
                  {markerDetails?.title}
                </div>
                <div className={styles["head__tags"]}>
                  Протипіхотні фугасні міни
                </div>
                <div className={styles["head__coordinate"]}>
                  48°10'09.2"N 37°45'00.5"E
                </div>
              </div>
            </div>
            <div className={styles["details__layer"]}>RGB</div>
            <div className={styles["details__slider"]}>
              <Slider />
            </div>
            <div className={styles["details__buttons"]}>
              <SecondaryButton icon={messageCircleIcon}>
                Коментувати
              </SecondaryButton>
              <SecondaryButton icon={startIcon}>Помітити</SecondaryButton>
              <SecondaryButton icon={trashIcon}>Видалити</SecondaryButton>
            </div>
            <div className={styles["details__other-data"]}>
              <div className={styles["other-data__title"]}>
                Other data in this point
              </div>
              <div className={styles["other-data__tags"]}>
                <span>RGB</span>
                <span>Multispectral</span>
              </div>
            </div>
          </div>
        </PreloaderWrapper>
      </div>
    </div>
  );
};

export default MapMarkerDetails;
