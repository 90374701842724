import { useCallback } from "react";
import { useMap } from "react-leaflet";

import ZoomInIcon from "assets/icons/zoom-in.svg";
import ZoomOutIcon from "assets/icons/zoom-out.svg";

import styles from "./map-zoom-btns.module.scss";

const MapZoomBtns = () => {
  const map = useMap();

  const mapZoomIn = useCallback(() => map.zoomIn(), [map]);
  const mapZoomOut = useCallback(() => map.zoomOut(), [map]);
  return (
    <div className={styles["buttons"]}>
      <button onClick={mapZoomIn}>
        <img src={ZoomInIcon} alt="map-zoom-in" />
      </button>
      <div className={styles["divider"]} />
      <button onClick={mapZoomOut}>
        <img src={ZoomOutIcon} alt="map-zoom-out" />
      </button>
    </div>
  );
};

export default MapZoomBtns;
