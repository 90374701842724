import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const Error = (props: any): JSX.Element => {
  return (
    <Layout>
      <features.error.page {...props} />
    </Layout>
  );
};

export default Error;
