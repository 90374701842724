import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const OrthophotoPlanning = (props: any): JSX.Element => {
  return (
    <Layout title="Orthophoto Planning">
      <features.orthophotoPlanning.page {...props} />
    </Layout>
  );
};

export default OrthophotoPlanning;
