import { createSlice } from "@reduxjs/toolkit";
import { AREA_COLORS } from "constant";

export interface MapLayerInterface {
  name: string;
  color: string;
  icon: string;
  isActive: boolean;
  alias: string;
}
export interface MapAriesInterface {
  name: string;
  color: string;
  isActive: boolean;
  alias: string;
}

interface GeoDataInterface {
  type: string;
  coordinates: number[];
}

export interface MarkerInterface {
  color: string;
  created_at: string;
  geo_data: GeoDataInterface;
  id: string;
  layer: string;
  name: string;
  probability: number;
}

export interface PolygonInterface {
  coordinates: number[];
  id: string;
  alias: string;
  color: string;
  text: string;
  isActive?: boolean;
}

export interface MapStateInterface {
  projectTitle: string;
  layers: MapLayerInterface[];
  aries: MapAriesInterface[];
  isLoadingPolygons: boolean;
  isLoading: boolean;
  isLoadingMarkers: boolean;
  isLoadingMarkerDetails: boolean;
  polygons: MarkerInterface[];
  markers: MarkerInterface[];
  markerDetails: MarkerDetailsInterface;
}

export interface MarkerDetailsInterface {
  title: string;
  id: string;
  imgs: string[];
}

const initialState: MapStateInterface = {
  isLoading: false,
  isLoadingMarkers: false,
  isLoadingMarkerDetails: false,
  isLoadingPolygons: false,
  projectTitle: "",
  layers: [],
  aries: [],
  markers: [],
  polygons: [],
  markerDetails: null
};

export const mainSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    fetchLayersRequest: (state) => {
      state.isLoading = true;
    },
    fetchLayersSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.layers = payload.map((layer) => {
        return { ...layer, isActive: layer.is_default };
      });
    },
    fetchLayersError: (state) => {
      state.isLoading = false;
    },
    fetchAriesRequest: (state) => {
      state.isLoading = true;
    },
    fetchAriesSuccess: (state, {}) => {
      //TODO colors from back-end
      state.isLoading = false;
      state.aries = AREA_COLORS;
    },
    fetchAriesError: (state) => {
      state.isLoading = false;
    },

    fetchMarkersRequest: (state) => {
      state.isLoadingMarkers = true;
    },
    fetchMarkersSuccess: (state, { payload }) => {
      state.isLoadingMarkers = false;
      const newMarkers = payload.points.map((marker) => {
        return {
          ...marker,
          color: state.layers.find((layer) => layer.alias === marker.layer)
            .color
        };
      });
      state.markers = [...state.markers, ...newMarkers];
      state.polygons = state.markers;
    },
    fetchMarkersError: (state) => {
      state.isLoadingMarkers = false;
    },
    updateMarkersByOrthophoto: (state, { payload }) => {
      state.isLoadingMarkers = false;
      const newMarkers = payload.map((marker) => {
        return {
          ...marker,
          color: state.layers.find((layer) => layer.alias === marker.layer)
            .color
        };
      });
      state.markers = newMarkers;
      state.polygons = payload;
    },
    fetchPolygonsRequest: (state) => {
      state.isLoadingPolygons = true;
    },
    fetchPolygonsSuccess: (state, { payload }) => {
      state.isLoadingPolygons = false;
      state.polygons = payload;
    },
    fetchPolygonsError: (state) => {
      state.isLoadingPolygons = false;
    },

    fetchMarkerDetailsRequest: (state) => {
      state.isLoadingMarkerDetails = true;
    },
    fetchMarkerDetailsSuccess: (state, { payload }) => {
      state.isLoadingMarkerDetails = false;
      state.markerDetails = payload;
    },
    fetchMarkerDetailsError: (state) => {
      state.isLoadingMarkerDetails = false;
    },
    clearMarkerDetails: (state) => {
      state.markerDetails = null;
    },

    toggleLayerIsActive: (state, { payload }) => {
      state.layers = state.layers.map((item) =>
        item.alias === payload.params.alias
          ? { ...item, isActive: !item.isActive }
          : item
      );
    },
    toggleAriaIsActive: (state, { payload }) => {
      state.aries = state.aries.map((item) =>
        item.alias === payload ? { ...item, isActive: !item.isActive } : item
      );
    }
  }
});

export const { actions, reducer } = mainSlice;
