import cn from "classnames";

import useOutsideClick from "helpers/useOutsideClick";

import styles from "./dropdown-menu.module.scss";

interface DropdownMenuInterface {
  //TODO add interface
  options: any[];
  isActive: boolean;
  onClick: (option) => void;
}

const DropdownMenu = ({
  options,
  isActive,
  onClick
}: DropdownMenuInterface) => {
  const ref = useOutsideClick(onClick);
  const optionClick = (e, option) => {
    e.stopPropagation();
    onClick(option);
  };

  return (
    <div
      className={cn(styles["dropdown"], {
        [styles["dropdown--active"]]: isActive
      })}
      ref={ref}
    >
      {options.map((item, i) => {
        return (
          <div
            className={styles["option"]}
            key={`option${i}`}
            onClick={(e) => optionClick(e, item)}
          >
            {item.icon && (
              <div className={styles["option-icon"]}>
                <img src={item.icon} alt={`{item.label}-icon`} />
              </div>
            )}
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default DropdownMenu;
