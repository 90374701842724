import styles from "./common-button.module.scss";

interface CommonButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  icon: string;
  children: string;
  onClick?: () => void;
}

const CommonButton = ({
  type,
  children,
  icon,
  onClick
}: CommonButtonInterface) => {
  return (
    <button
      type={type ?? "button"}
      className={styles["secondary"]}
      onClick={onClick}
    >
      {icon && <img src={icon} alt={`${children} button icon`} />}
      {children}
    </button>
  );
};

export default CommonButton;
