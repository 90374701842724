import styles from "./main-block.module.scss";

export interface MainBlockInterface {
  title: string;
  link: string;
  icon: string;
}

const MainBlock = ({ title, link, icon }: MainBlockInterface) => {
  return (
    <a className={styles["main-block__wrapper"]} href={link}>
      <div className={styles["main-block__icon"]}>
        <img src={icon} alt={`${title} logo`}></img>
      </div>
      <div className={styles["main-block__title"]}>{title}</div>
    </a>
  );
};

export default MainBlock;
