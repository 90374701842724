import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "components/Header/Header";
import Toast from "components/Toast/Toast";
import ModalRoot from "components/modals/ModalRoot";

import styles from "./layout.module.scss";

interface LayoutInterface {
  title?: string;
  isFullFill?: boolean;
  isWithoutHeader?: boolean;
  children: any;
  backPath?: string;
}

const Layout = ({
  title,
  backPath,
  isFullFill,
  isWithoutHeader,
  children
}: LayoutInterface) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!isWithoutHeader && (
        <Header title={title} isFullFill={isFullFill} backPath={backPath} />
      )}
      <main className={styles[isFullFill ? "main-full-fill" : "main"]}>
        {children}
      </main>
      <Toast />
      <ModalRoot />
    </>
  );
};

export default Layout;
