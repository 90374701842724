//TODO remove unused code and css

import cn from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import features from "features";

import Preloader from "components/Preloader/Preloader";

import styles from "./preloader-wrapper.module.scss";

interface IPreloaderWrapper {
  loading?: boolean | string;
  isAbsolute?: boolean;
  children?: any;
  opacity?: boolean;
  withModal?: boolean;
}

const PreloaderWrapper = (props: IPreloaderWrapper) => {
  const { loading, isAbsolute, children, opacity, withModal } = props;
  const dispatch = useDispatch();

  // TODO
  useEffect(() => {
    if (withModal && loading) console.log("TODO");
    // dispatch(
    //   features.modal.actions.showModal({
    //     modalType: "PRELOADER",
    //     modalProps: {
    //       title: "Опрацювання запиту",
    //       loading: true,
    //       disableClose: true
    //     }
    //   })
    // );
    else if (withModal && !loading) {
      dispatch(features.modal.actions.hideModal());
    }
  }, [withModal, loading, dispatch]);

  return (
    <div
      className={cn(styles["preloader-container"], {
        [styles["preloader-container-loading"]]: loading,
        [styles["opacity"]]: opacity
      })}
    >
      {loading && <Preloader isChildPreloader isAbsolute={isAbsolute} />}
      {children}
    </div>
  );
};

export default PreloaderWrapper;
