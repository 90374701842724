import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman";
import * as turf from "@turf/turf";
import L from "leaflet";
import { Polygon } from "react-leaflet";

interface MapPolygonsInterface {
  aries: any;
  markers: any;
}
const MapPolygons = ({ aries, markers }: MapPolygonsInterface) => {
  const circleToPolygon = ({ centers, radius }) => {
    return centers.map((center) => {
      const circle = L.circle(center.geo_data.coordinates, { radius });
      const circlePolygon = L.PM.Utils.circleToPolygon(circle);
      return turf.polygon([
        [
          ...circlePolygon
            .getLatLngs()[0]
            //@ts-ignore
            .map((latlng) => [latlng.lng, latlng.lat]),
          circlePolygon
            .getLatLngs()[0]
            //@ts-ignore
            .map((latlng) => [latlng.lng, latlng.lat])[0]
        ]
      ]);
    });
  };

  const groupePolygons = (polygons) => {
    const unionPolygonsInGroups = [];

    polygons.forEach((polygon) => {
      let isOverlapping: boolean = false;
      let isUsedInId: number;
      for (let i = 0; i < unionPolygonsInGroups.length; i++) {
        if (
          turf.intersect(
            turf.featureCollection([unionPolygonsInGroups[i], polygon])
          )
        ) {
          isOverlapping = true;
          if (isUsedInId !== undefined) {
            unionPolygonsInGroups[isUsedInId] = turf.union(
              turf.featureCollection([
                unionPolygonsInGroups[isUsedInId],
                unionPolygonsInGroups[i],
                polygon
              ])
            );
            unionPolygonsInGroups.splice(i, 1);
          } else {
            unionPolygonsInGroups[i] = turf.union(
              turf.featureCollection([unionPolygonsInGroups[i], polygon])
            );
            isUsedInId = i;
          }
        }
      }

      if (!isOverlapping) {
        unionPolygonsInGroups.push(polygon);
      }
    });

    return unionPolygonsInGroups;
  };

  const generatePolygons = ({ centers, radius }) => {
    const polygons = circleToPolygon({ centers, radius });
    const groupedPolygons = groupePolygons(polygons);

    const unionCoordinates = groupedPolygons.map((polygon) =>
      polygon.geometry.coordinates[0].map((coord) => ({
        lat: coord[1],
        lng: coord[0]
      }))
    );

    return unionCoordinates;
  };

  return (
    <>
      {aries.map((area, i) => (
        <Polygon
          positions={generatePolygons({
            centers: markers,
            radius: area.radius
          })}
          color={area}
          pathOptions={{
            color: area.color,
            weight: 1,
            opacity: 1,
            dashArray: "5, 5",
            dashOffset: "0",
            // fillOpacity:
            //   isDisabledDefault && polygon[0].alias === "All" ? 0.1 : 0.5
            fillOpacity: 0.5
          }}
          key={`polygon-${i}`}
        />
      ))}
      {/* {markers.map((polygon, i) => (
        <Polygon
          positions={circleToPolygon({ centers: markers.geo_data, radius: 5 })}
          color={AREA_COLORS["red"]}
          pathOptions={{
            color: AREA_COLORS["red"],
            weight: 1,
            opacity: 1,
            dashArray: "5, 5",
            dashOffset: "0",
            fillOpacity:
              isDisabledDefault && polygon[0].alias === "All" ? 0.1 : 0.5
          }}
          key={`polygon-${i}`}
        />
      ))} */}
    </>
  );
};
export default MapPolygons;
