import styles from "./map-pie-marker-icon.module.scss";

const MapPieMarkerIcon = ({
  color,
  probability,
  isActive
}: {
  color: string;
  probability: number;
  isActive?: boolean;
}) => {
  const colors = [
    {
      color: "red",
      percent: 11
    },
    {
      color: "green",
      percent: 17
    },
    {
      color: "orange",
      percent: 5
    },
    {
      color: "darkblue",
      percent: 18
    },
    {
      color: "silver",
      percent: 14
    },
    {
      color: "black",
      percent: 5
    },
    {
      color: "purple",
      percent: 30
    }
  ];

  const getBackgroundPieChart = () => {
    let colorGradientValues: string = "";
    let sum = 0;

    colors.forEach((element, i, array) => {
      const isLastElement = i + 1 === array.length;
      colorGradientValues += `${element.color} ${sum}% `;
      if (!isLastElement) colorGradientValues += `${element.percent + sum}%,`;

      sum += element.percent;
    });

    const conicGradient = `conic-gradient(${colorGradientValues})`;

    return conicGradient;
  };

  return (
    <div className={styles["marker"]} data-testid="marker">
      {isActive && <span className={styles["marker__bullet"]} />}
      <span style={{ borderColor: color }} className={styles["marker__text"]}>
        {Math.round(probability * 100)}%
      </span>
      <span
        style={{
          background: getBackgroundPieChart()
        }}
        className={styles["marker__pie"]}
      />
    </div>
  );
};
export default MapPieMarkerIcon;
