import axios from "axios";

import { AUTH_URL } from "constant";

export const auth = ({ params }) => {
  const initTokenClient = axios.create({
    baseURL: AUTH_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${params.clientToken}`
    }
  });

  return initTokenClient
    .post(`/auth`)
    .then((response) => response)
    .catch((error) => error.response);
};
