import styles from "./burger.module.scss";

const BurgerButton = () => {
  return (
    <div className={styles["burger"]}>
      <div className={styles["line"]}></div>
      <div className={styles["line"]}></div>
      <div className={styles["line"]}></div>
    </div>
  );
};

export default BurgerButton;
