import { call, put } from "redux-saga/effects";

import { env } from "../env";
import { setRequestError } from "scenes/ErrorPage/saga";

function* requestMiddleware({
  request,
  params,
  fields,
  setSuccess,
  setError,
  onSuccess,
  onError,
  isReturnRequestPayload
}) {
  try {
    const data = yield call(request, { params, fields });

    if (data && data.status >= 200 && data.status < 300) {
      if (setSuccess)
        isReturnRequestPayload
          ? yield put(setSuccess({ data: data.data, params, fields }))
          : yield put(setSuccess(data.data));

      if (onSuccess)
        isReturnRequestPayload
          ? yield onSuccess({ data: data.data, params, fields })
          : yield onSuccess(data.data);
    } else {
      yield put(setError(data.data));

      if (onError) yield onError(data.data);
      yield call(setRequestError, data);
    }
  } catch (err) {
    if (env.NODE_ENV === "development") {
      console.log(err);
    }
    yield put(setError());
    yield call(setRequestError, err);
    if (onError) yield onError();
  }
}

export { requestMiddleware };
