import { Options, Splide, SplideSlide } from "@splidejs/react-splide";

import img from "assets/DJI_20240405162338_0092_W 1.png";

import styles from "./slider.module.scss";

const Slider = () => {
  const options: Options = {
    arrows: false,
    classes: {
      pagination: `splide__pagination ${styles["slider__pagination"]}`,
      page: `splide__pagination__page ${styles["slider__page"]}`
    }
  };

  return (
    <Splide options={options} aria-label="RGB">
      <SplideSlide>
        <img src={img} alt="1" className={styles["slider__image"]} />
      </SplideSlide>
      <SplideSlide>
        <img src={img} alt="2" className={styles["slider__image"]} />
      </SplideSlide>
      <SplideSlide>
        <img src={img} alt="3" className={styles["slider__image"]} />
      </SplideSlide>
    </Splide>
  );
};

export default Slider;
