import "./App.css";
// import ImageUploadAndLabel from "./ImageUploadAndLabel";
import Routes from "./Routes";

function App() {
  return (
    <Routes />
  );
}

export default App;
