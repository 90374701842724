import { useCallback, useState } from "react";

import DropdownMenu from "components/DropdownMenu/DropdownMenu";

import CopyIcon from "assets/icons/copy.svg";
import DotsIcon from "assets/icons/dots.svg";
import Trash2Icon from "assets/icons/trash-2.svg";
import ProjectPreviewImg from "assets/tmporary-project-preview.png";

import styles from "./project-preview.module.scss";

const ProjectPreview = () => {
  const [isActive, setIsActive] = useState(false);

  const clickHandler = (val) => {
    setIsActive(val);
  };

  const optionChoseHandler = useCallback((option) => {
    console.log(option);
    clickHandler(false);
  }, []);

  return (
    <div className={styles["wrapper"]}>
      <a href="/map" className={styles["wrapper__picture"]}>
        <img src={ProjectPreviewImg} alt="preview" />
      </a>
      <div className={styles["wrapper__details"]}>
        <a href="/map" className={styles["wrapper__title"]}>
          Poligon 1
        </a>
        <div className={styles["wrapper__date"]}>13 June 2023</div>
        <div
          className={styles["wrapper__more"]}
          onClick={() => {
            setTimeout(() => {
              clickHandler(true);
            }, 0);
          }}
        >
          <img src={DotsIcon} alt="dots" />
          <DropdownMenu
            options={[
              { label: "Delete", icon: Trash2Icon },
              { label: "Duplicate", icon: CopyIcon }
            ]}
            isActive={isActive}
            onClick={optionChoseHandler}
          />
        </div>
      </div>
    </div>
  );
};
export default ProjectPreview;
