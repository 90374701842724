import cn from "classnames";
import { ErrorMessage, Field, FormikErrors, FormikTouched } from "formik";

import styles from "./checkbox.module.scss";

export interface iCheckbox {
  name: string;
  title?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  onClick?: (e: any) => void;
  errors?: FormikErrors<{ email: string }>;
  touched?: FormikTouched<{ email: string }>;
  errClassName?: string;
  errComponent?: React.ComponentType;
  label?: string;
}

const Checkbox = ({
  name,
  title,
  checked,
  disabled,
  className,
  wrapperClassName,
  onClick,
  errors,
  touched,
  errComponent,
  errClassName,
  label
}: iCheckbox): JSX.Element => {
  return (
    <div
      className={cn(styles["checkbox-component__container"], wrapperClassName)}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <label
        className={cn(styles["checkbox"], className, {
          [styles["checkbox_active"]]: checked,
          [styles["checkbox_disabled"]]: disabled
        })}
      >
        <Field
          type="checkbox"
          name={name}
          className={styles["checkbox__input"]}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
        />
        <span className={styles["checkbox__indicator"]} />
        <div className={styles["checkbox__info"]}>
          <span className={styles["checkbox__info-title"]}>{title}</span>
        </div>
        {errors && touched && (
          <ErrorMessage
            name={name}
            component={errComponent || "div"}
            className={cn(styles["checkbox-warning"], errClassName)}
          />
        )}
      </label>
      {label && <label>{label}</label>}
    </div>
  );
};

export default Checkbox;
