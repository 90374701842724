import { ExternalProvbider, OVDProvbider } from "../ApiServiceProviders";

export const getOrthophotoOptions = () => {
  return ExternalProvbider.client
    .get(`/options`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const createOrthophotoTask = ({ params }) => {
  return OVDProvbider.clientUpload
    .post(`/inference/back/odm/orthophotoplan/create`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOrthophotoTaskInfo = ({ params }) => {
  return OVDProvbider.clientUpload
    .get(`/inference/back/odm/orthophotoplan/${params.uuid}/info`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const uploadOrthophoto = ({ params }) => {
  return OVDProvbider.clientUpload
    .post(`/inference/back/ai/upload/geojson`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
