import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";
import Preloader from "components/Preloader/Preloader";

import styles from "./preloader-modal.module.scss";

interface PreloaderModalInterface {
  title?: string;
  loading: string;
  disableClose?: boolean;
}

const PreloaderModal = () => {
  const { title, loading, disableClose } = useSelector<
  RootStateInterface,
    PreloaderModalInterface
  >((state) => state.modal.modalProps);

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      disableClose={disableClose}
    >
      <div className={styles["preloader-modal"]}>
        <h2 className={styles["preloader-modal__title"]}>{title}</h2>
        <Preloader loading={loading}/>
      </div>
    </ModalWrapper>
  );
};
export default PreloaderModal;
