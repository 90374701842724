import { toastr } from "react-redux-toastr";
import { put } from "redux-saga/effects";
import features from "features";

const errors = {
  UNKNOWN_ERROR: 0,
  INVALID_TOKEN: 401,
  EXPIRED_TOKEN: 403,
  NOT_FOUND: 404,
  FORM_ERROR: 422,
  BAD_REQUEST: 400,
  MANY_ATTEMPTS: 429
};

export function* setRequestError(response) {
  const status = (response && response.status) || null;

  switch (status) {
    case errors.NOT_FOUND:
      return;
    case errors.BAD_REQUEST:
      return;
    case errors.MANY_ATTEMPTS:
      toastr.error(
        `Помилка`,
        `Занадто багато спроб, спробуйте через пару хвилин`
      );
      return;
    case errors.INVALID_TOKEN:
    case errors.EXPIRED_TOKEN:
      yield put(features.auth.actions.signOutRequest());
      return;
    default: {
      toastr.error(`Помилка`, `Щось пішло не так. Спробуйте пізніше.`);
    }
  }
}
