import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import features from "features";
import { RootStateInterface } from "reducer";
import {
  OrthophotoOptionInterface,
  OrthophotoPlanningStateInterface
} from "../ducks";
import {
  downloadOrthophotoCoreLink,
  taskItemLables,
  taskStatuses,
  taskStatusesTitles
} from "./constant";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "components/buttons/Secondary/Secondary";
import Checkbox from "components/inputs/Checkbox/Checkbox";
import FormField from "components/inputs/FormField/FormField";
import FormSelect from "components/inputs/FormSelect/FormSelect";
import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

import eyeCloseIcon from "assets/icons/eye-close.svg";
import eyeOpenIcon from "assets/icons/eye-open.svg";

import styles from "./orthophoto-planning-page.module.scss";

interface InitialOptionsInterface extends OrthophotoOptionInterface {
  images?: any;
}

const OrthophotoPlanningPage = () => {
  const dispatch = useDispatch();
  const [isOptionsOpened, setIsOptionsOpened] = useState(false);

  const {
    isOptionsLoading,
    orthophotoOptions,
    isCreateOrthophotoTaskLoading,
    createdOrthophotoTaskUuid,
    orthophotoTaskInfo
  } = useSelector<RootStateInterface, OrthophotoPlanningStateInterface>(
    (state) => state.orthophotoPlanning
  );

  useEffect(() => {
    dispatch(
      features.orthophotoPlanning.actions.fetchOrthophotoOptionsRequest()
    );
  }, []);

  const toggleOptionsView = () => {
    setIsOptionsOpened((prewState) => !prewState);
  };

  const initialFormikValues: InitialOptionsInterface = useMemo(
    () =>
      orthophotoOptions.reduce(
        (acc, { name, value, type }) => {
          if (type === "bool") {
            acc[name] = JSON.parse(value);
          } else if (type === "enum") {
            acc[name] = { label: value, value: value };
          } else {
            acc[name] = value;
          }
          return acc;
        },
        {
          name: "",
          images: null
        } as InitialOptionsInterface
      ),
    [orthophotoOptions]
  );

  const handleFilesChange = (event) => {
    const files = event.target.files;
    formik.setFieldValue("images", files);
  };

  const handleFormSubmit = (values) => {
    if (!values.images) {
      toastr.error("Error", "Please select a file before upload.");
      return;
    }

    const changedOptions = Object.fromEntries(
      Object.entries(values)
        .filter(([optionName, optionValue]: any) => {
          if (typeof optionValue === "object") {
            return (
              optionValue?.value !== initialFormikValues[optionName]?.value
            );
          }
          return optionValue !== initialFormikValues[optionName];
        })
        .map(([optionName, optionValue]: any) =>
          typeof optionValue === "object"
            ? [optionName, optionValue.value]
            : [optionName, optionValue]
        )
    );

    const orthophotoData = {
      options: Object.keys(changedOptions)
        .filter((key) => key !== "name")
        .map((key) => ({
          name: key,
          value:
            typeof changedOptions[key] === "object"
              ? changedOptions[key].value
              : changedOptions[key]
        })),
      images: values.images,
      name: values.name
    };

    const formData = new FormData();

    Array.from(orthophotoData.images).forEach((file: File) =>
      formData.append("images", file)
    );
    formData.append("name", orthophotoData.name);
    formData.append("options", JSON.stringify(orthophotoData.options));

    dispatch(
      features.orthophotoPlanning.actions.fetchCreateOrthophotoTaskRequest({
        params: formData
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: initialFormikValues,
    validateOnMount: true,
    enableReinitialize: true,
    // validationSchema: Yup.object().shape({}),
    onSubmit: handleFormSubmit
  });

  const handleSelectChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const renderCorrectField = useCallback(
    (option) => {
      if (option.type === "enum")
        return (
          <FormSelect
            name={option.name}
            value={formik.values[option.name]}
            label={option.name}
            placeholder={option.value}
            className={styles["create-invoice__info-input-country"]}
            options={option.domain.map((el) => ({ label: el, value: el }))}
            isSearchable={false}
            onSelectChange={handleSelectChange}
            key={option.name}
          />
        );
      if (
        option.type === "int" ||
        option.type === "string" ||
        option.type === "float"
      )
        return (
          <FormField
            name={option.name}
            label={option.label ?? option.name}
            placeholder={option.value}
            className={styles["create-invoice__info-input-country"]}
            key={option.name}
          />
        );
      if (option.type === "bool")
        return (
          <Checkbox
            name={option.name}
            title={"Enable"}
            label={option.name}
            checked={formik.values[option.name]}
            onClick={() => {
              formik.setFieldValue(option.name, !formik.values[option.name]);
            }}
            key={option.name}
          />
        );
    },
    [orthophotoOptions.length, isOptionsOpened, formik]
  );

  useEffect(() => {
    if (createdOrthophotoTaskUuid) {
      dispatch(
        features.orthophotoPlanning.actions.fetchOrthophotoTaskInfoRequest({
          params: { uuid: createdOrthophotoTaskUuid }
        })
      );
    }
  }, [createdOrthophotoTaskUuid]);

  useEffect(() => {
    const getOrthophotoDispatch = () =>
      dispatch(
        features.orthophotoPlanning.actions.fetchOrthophotoTaskInfoRequest({
          params: { uuid: createdOrthophotoTaskUuid }
        })
      );

    if (
      orthophotoTaskInfo &&
      orthophotoTaskInfo?.status.code === taskStatuses.running
    )
      setTimeout(() => getOrthophotoDispatch(), 1500);
    if (
      orthophotoTaskInfo &&
      orthophotoTaskInfo?.status.code === taskStatuses.queued
    )
      setTimeout(() => getOrthophotoDispatch(), 3000);
  }, [orthophotoTaskInfo]);

  const handleOrthophotoDownload = () => {
    window.location.href = `${downloadOrthophotoCoreLink}/${createdOrthophotoTaskUuid}`;
  };

  return (
    <PreloaderWrapper
      loading={isOptionsLoading || isCreateOrthophotoTaskLoading}
    >
      <div className={styles["orthophoto-page"]}>
        <FormikProvider value={formik}>
          <Form>
            <UploadFiles handleFileChange={handleFilesChange} isMultiple />
            <div className={styles["orthophoto-page__buttons-container"]}>
              <SecondaryButton
                icon={isOptionsOpened ? eyeOpenIcon : eyeCloseIcon}
                onClick={toggleOptionsView}
              >
                {!isOptionsOpened ? "Show Options" : "Hide Options"}
              </SecondaryButton>
              <PrimaryButton type="submit">Start Task</PrimaryButton>
            </div>
            {isOptionsOpened && (
              <div className={styles["orthophoto-page__options-container"]}>
                {orthophotoOptions.length > 0 &&
                  [
                    {
                      name: "name",
                      type: "string",
                      value: "",
                      domain: "string",
                      help: "Assign a name to the project.",
                      label: "Project name"
                    },
                    ...orthophotoOptions
                  ].map((option) => renderCorrectField(option))}
              </div>
            )}
          </Form>
        </FormikProvider>
        <div className={styles["tasks-container"]}>
          {orthophotoTaskInfo && (
            <div className={styles["tasks-wrapper"]}>
              {Object.keys(orthophotoTaskInfo).map(
                (key) =>
                  key !== "options" &&
                  (key === "progress" && orthophotoTaskInfo[key] === 100 ? (
                    <></>
                  ) : (
                    <div className={styles["task-item"]} key={key}>
                      <strong>{taskItemLables[key]}:</strong>
                      {key === "progress" ? (
                        <progress value={orthophotoTaskInfo[key]} max={100} />
                      ) : (
                        <span>
                          {key === "status"
                            ? taskStatusesTitles[orthophotoTaskInfo[key].code]
                            : orthophotoTaskInfo[key]}
                        </span>
                      )}
                    </div>
                  ))
              )}
              {orthophotoTaskInfo?.status.code === 40 && (
                <PrimaryButton type="button" onClick={handleOrthophotoDownload}>
                  Download
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      </div>
    </PreloaderWrapper>
  );
};

export default OrthophotoPlanningPage;
