import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const Map = (props: any): JSX.Element => {
  const backPath = "/";

  return (
    <Layout title="Map" isFullFill backPath={backPath}>
      <features.map.page {...props} />
    </Layout>
  );
};

export default Map;
