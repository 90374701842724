import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const auth = (props: any): JSX.Element => {
  return (
    <Layout isWithoutHeader isFullFill>
      <features.auth.page {...props} />
    </Layout>
  );
};

export default auth;
