import styles from "./primary-button.module.scss";

interface PrimaryButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: string;
  onClick?: () => void;
  icon?: string;
}

const PrimaryButton = ({
  type,
  children,
  onClick,
  icon
}: PrimaryButtonInterface) => {
  return (
    <button type={type} className={styles["primary"]} onClick={onClick}>
      {icon && (
        <div className={styles["primary__icon"]}>
          <img src={icon} alt={children + "-icon"} />
        </div>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
