import classNames from "classnames";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import features from "features";
import { MapLayerInterface as MapLayerDataInterface } from "scenes/MapPage/ducks";

import EyeCloseIcon from "assets/icons/eye-close.svg";
import EyeOpenIcon from "assets/icons/eye-open.svg";

import styles from "./map-layer.module.scss";

interface MapLayerInterface extends MapLayerDataInterface {
  isPolygon?: boolean;
}

const MapLayer = ({
  name,
  color,
  isActive,
  alias,
  isPolygon
}: MapLayerInterface) => {
  const dispatch = useDispatch();

  const onToggleLayerIsActive = useCallback(() => {
    isPolygon
      ? dispatch(features.map.actions.toggleAriaIsActive(alias))
      : dispatch(
          features.map.actions.toggleLayerIsActive({ params: { alias } })
        );
  }, [alias, dispatch, isPolygon]);

  return (
    <div
      className={classNames(styles["layer"], {
        [styles["layer--active"]]: isActive
      })}
      onClick={onToggleLayerIsActive}
    >
      <span
        className={styles["layer__disk"]}
        style={{ backgroundColor: color }}
      />
      <span className={styles["layer__title"]}>{name}</span>
      <span className={styles["layer__icon"]}>
        <img
          src={isActive ? EyeOpenIcon : EyeCloseIcon}
          alt={`layer-${isActive ? "active" : "hidden"}`}
        ></img>
      </span>
    </div>
  );
};
export default MapLayer;
