import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";
import { IModalState } from "scenes/Modal/ducks";

import PreloaderModal from "./PreloaderModal/PreloaderModal";

const MODAL_COMPONENTS = {
  PRELOADER: PreloaderModal
};

const ModalRoot = () => {
  const { modalType, modalProps } = useSelector<
    RootStateInterface,
    IModalState
  >((state) => state.modal);

  if (!modalType) {
    return <></>;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

export default ModalRoot;
