import L, { DivIcon, LatLngExpression } from "leaflet";
import { useCallback } from "react";
import { renderToString } from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import { useDispatch } from "react-redux";

import features from "features";
import { MarkerInterface } from "scenes/MapPage/ducks";

import MapMarkerIcon from "../MapMarkerIcon/MapMarkerIcon";
import MapPieMarkerIcon from "../MapPieMarkerIcon/MapPieMarkerIcon";

interface MapMarkersInterface {
  markers: MarkerInterface[];
  isDisabledDefault: boolean;
  activeMarkerId: string;
}

const MapMarkers = ({
  markers,
  isDisabledDefault,
  activeMarkerId
}: MapMarkersInterface) => {
  const dispatch = useDispatch();
  const map = useMap();

  const getMarkerIcon = (marker) => {
    return new DivIcon({
      html: renderToString(
        marker.layer === "all" ? (
          <MapPieMarkerIcon
            color={marker.color}
            probability={marker.probability}
            isActive={activeMarkerId === marker.id}
          />
        ) : (
          <MapMarkerIcon
            color={marker.color}
            probability={marker.probability}
            isActive={activeMarkerId === marker.id}
          />
        )
      ),
      className: "marker-reset",
      iconSize: [38, 52],
      iconAnchor: marker.alias === "All" ? [19, 25] : [19, 26]
    });
  };

  const shiftMapLeft = useCallback(
    (coordinates) => () => {
      const screenWidthInPixels = window.innerWidth / 4;
      const centerPoint = map.latLngToContainerPoint(coordinates);
      const newPoint = L.point([
        centerPoint.x - screenWidthInPixels,
        centerPoint.y
      ]);
      const newLatLng = map.containerPointToLatLng(newPoint);

      map.panTo(newLatLng);
    },
    []
  );

  const onMarkerClick = useCallback(
    (marker) => () => {
      dispatch(
        features.map.actions.fetchMarkerDetailsRequest({
          params: { id: marker.id },
          onSuccess: shiftMapLeft(marker.coordinates)
        })
      );
    },
    []
  );

  return (
    <>
      {markers.map((marker: MarkerInterface, index: number) => (
        <Marker
          position={marker.geo_data.coordinates as LatLngExpression}
          icon={getMarkerIcon(marker)}
          opacity={isDisabledDefault && marker.layer === "All" ? 0.5 : 1}
          eventHandlers={{
            click: onMarkerClick(marker)
          }}
          key={`marker-${index}`}
        />
      ))}
    </>
  );
};
export default MapMarkers;
