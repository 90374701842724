//TODO remove unused code and css

import cn from "classnames";

import styles from "./preloader.module.scss";

const Preloader = (props) => {
  const { isSmall, isBig, isChildPreloader, isAbsolute } = props;

  return (
    <div
      className={cn(styles["container"], {
        [styles["container-child-preloader"]]: isChildPreloader,
        [styles["is-small"]]: isSmall,
        [styles["is-big"]]: isBig,
        [styles["absolute"]]: isAbsolute
      })}
    >
      <div className={styles["loader"]}></div>
    </div>
  );
};

export default Preloader;
