import cn from "classnames";
import { forwardRef, LegacyRef } from "react";

import styles from "./upload-files.module.scss";
interface UploadFilesInterface {
  handleFileChange: (event: any) => void;
  isMultiple?: boolean;
  className?: string;
  ref?: any;
}
const UploadFiles = forwardRef(
  (
    { handleFileChange, isMultiple, className }: UploadFilesInterface,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    return (
      <input
        className={cn(styles["upload__input"], className)}
        type="file"
        id="files"
        aria-label="files"
        onChange={handleFileChange}
        multiple={isMultiple}
        ref={ref}
      />
    );
  }
);

export default UploadFiles;
