import { select, takeLatest } from "redux-saga/effects";

import { requestMiddleware } from "helpers/api";
import {
  getLayers,
  getMarkers,
  getMarkerDetails,
  getAries,
  getPolygons
} from "services/api/map";
import { actions } from "./ducks";

function* fetchLayers({ payload }) {
  const request = getLayers;

  const { fetchLayersSuccess: setSuccess, fetchLayersError: setError } =
    actions;

  function* onSuccess(res) {
    const alias = res.find((item) => item.is_default).alias;
    yield fetchMarkers({ payload: { params: { alias } } });
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}

function* fetchAries({ payload }) {
  const request = getAries;

  const { fetchAriesSuccess: setSuccess, fetchAriesError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* handleToggleLayerIsActive({ payload }) {
  const markers = yield select((state) => state.map.markers);
  const isMarkersLoaded = markers.find(
    (marker) => marker.layer === payload.params.alias
  );

  if (!isMarkersLoaded) yield fetchMarkers({ payload });
}

function* fetchMarkers({ payload }) {
  const request = getMarkers;

  const { fetchMarkersSuccess: setSuccess, fetchMarkersError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchPolygons({ payload }) {
  const request = getPolygons;

  const { fetchPolygonsSuccess: setSuccess, fetchPolygonsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchMarkerDetails({ payload }) {
  const request = getMarkerDetails;

  const {
    fetchMarkerDetailsSuccess: setSuccess,
    fetchMarkerDetailsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchLayersRequest, fetchLayers);
  yield takeLatest(actions.fetchAriesRequest, fetchAries);
  yield takeLatest(actions.fetchMarkersRequest, fetchMarkers);
  yield takeLatest(actions.fetchPolygonsRequest, fetchPolygons);
  yield takeLatest(actions.fetchMarkerDetailsRequest, fetchMarkerDetails);
  yield takeLatest(actions.toggleLayerIsActive, handleToggleLayerIsActive);
}
