import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ProjectPreview from "../ProjectPreview/ProjectPreview";
import Select from "../Select/Select";

import PlusIcon from "assets/icons/plus.svg";

import styles from "./projects-list.module.scss";

const ProjectsList = () => {
  const navigate = useNavigate();

  const onCreateProject = useCallback(() => {
    navigate("/map");
  }, [navigate]);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["wrapper__head"]}>
        <div className={styles["title"]}>Project</div>
        <Select options={[{ label: "Recently" }]} />
        <PrimaryButton icon={PlusIcon} onClick={onCreateProject}>
          New project
        </PrimaryButton>
      </div>
      <div className={styles["wrapper__body"]}>
        <ProjectPreview />
        {/* <ProjectPreview />
        <ProjectPreview /> */}
      </div>
    </div>
  );
};
export default ProjectsList;
