import { useMemo } from "react";

import MainBlock, { MainBlockInterface } from "components/MainBlock/MainBlock";

import AiHelperPicture from "assets/ai-helper-picture.png";
import LabelingPicture from "assets/labeling-picture.png";
import OdmPicture from "assets/odm-picture.png";
import WikiPicture from "assets/wiki-picture.png";

import styles from "./blocks-list.module.scss";

const ODM_LINK = "http://10.10.90.250:8000";
const CVAT_LINK = "http://10.10.90.250:8080";
const AI_HELPER_LINK = "https://ai-helper.vision.dropla.tech/helper/admin";
const WIKI_LINK = "https://wiki-admin.vision.dropla.tech";

const BlocksList = () => {
  //TODO change links
  const blockList = useMemo<Array<MainBlockInterface>>(
    () => [
      { title: "ODM", icon: OdmPicture, link: ODM_LINK },
      {
        title: "Labeling tool",
        icon: LabelingPicture,
        link: CVAT_LINK
      },
      {
        title: "AI Helper",
        icon: AiHelperPicture,
        link: AI_HELPER_LINK
      },
      {
        title: "Wiki mine",
        icon: WikiPicture,
        link: WIKI_LINK
      }
      //TODO delete image-detection and orthophoto-planning scenes
      // { title: "Mine detection", icon: MinaIcon, link: "/image-detection" },
      // { title: "Map", icon: MapIcon, link: "/map" },
      // {
      //   title: "Orthophoto Planning",
      //   icon: CameraIcon,
      //   link: "/orthophoto-planning"
      // }
    ],
    []
  );
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["wrapper__head"]}>
        <div className={styles["title"]}>Tools</div>
      </div>
      <div className={styles["wrapper__body"]}>
        <div className={styles["block-list"]}>
          {blockList.map((item, index) => (
            <MainBlock {...item} key={`block-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default BlocksList;
