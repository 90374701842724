import { createSlice } from "@reduxjs/toolkit";
import { formatDate } from "helpers/formatDate";
import { formatTime } from "helpers/formatTime";

export interface OrthophotoOptionInterface {
  name: string;
  type: string;
  value: string;
  domain?: String[] | string;
  help: string;
}

export interface OrthophotoPlanningStateInterface {
  isOptionsLoading: boolean;
  orthophotoOptions: OrthophotoOptionInterface[];
  isCreateOrthophotoTaskLoading: boolean;
  createdOrthophotoTaskUuid: string;
  isOrthophotoTaskInfoLoading: boolean;
  orthophotoTaskInfo: any;
  isUploadOrthophotoLoading: boolean;
  uploadedOrthophotoDetails: any;
}

const initialState: OrthophotoPlanningStateInterface = {
  isOptionsLoading: false,
  orthophotoOptions: [],
  isCreateOrthophotoTaskLoading: false,
  createdOrthophotoTaskUuid: "",
  isOrthophotoTaskInfoLoading: false,
  orthophotoTaskInfo: null,
  isUploadOrthophotoLoading: false,
  uploadedOrthophotoDetails: []
};

export const mainSlice = createSlice({
  name: "orthophotoPlanning",
  initialState,
  reducers: {
    fetchOrthophotoOptionsRequest: (state) => {
      state.isOptionsLoading = true;
    },
    fetchOrthophotoOptionsSuccess: (state, { payload }) => {
      state.isOptionsLoading = false;
      state.orthophotoOptions = payload;
    },
    fetchOrthophotoOptionsError: (state) => {
      state.isOptionsLoading = false;
    },
    fetchCreateOrthophotoTaskRequest: (state) => {
      state.isCreateOrthophotoTaskLoading = true;
    },
    fetchCreateOrthophotoTaskSuccess: (state, { payload }) => {
      state.isCreateOrthophotoTaskLoading = false;
      state.createdOrthophotoTaskUuid = payload;
    },
    fetchCreateOrthophotoTaskError: (state) => {
      state.isCreateOrthophotoTaskLoading = false;
      state.createdOrthophotoTaskUuid = "";
    },
    fetchOrthophotoTaskInfoRequest: (state) => {
      state.isOrthophotoTaskInfoLoading = true;
    },
    fetchOrthophotoTaskInfoSuccess: (state, { payload }) => {
      state.isOrthophotoTaskInfoLoading = false;
      state.orthophotoTaskInfo = {
        ...payload,
        dateCreated: formatDate(payload.dateCreated),
        processingTime: formatTime(payload.processingTime)
      };
    },
    fetchOrthophotoTaskInfoError: (state) => {
      state.isOrthophotoTaskInfoLoading = false;
      state.orthophotoTaskInfo = [];
    },
    fetchUploadOrthophotoRequest: (state) => {
      state.isUploadOrthophotoLoading = true;
    },
    fetchUploadOrthophotoSuccess: (state, { payload }) => {
      state.isUploadOrthophotoLoading = false;
      state.uploadedOrthophotoDetails = payload;
    },
    fetchUploadOrthophotoError: (state) => {
      state.isUploadOrthophotoLoading = false;
      state.uploadedOrthophotoDetails = [];
    }
  }
});

export const { actions, reducer } = mainSlice;
