import { createSlice } from "@reduxjs/toolkit";

export interface AuthStateInterface {
  isLoading: boolean;
}

const initialState: AuthStateInterface = {
  isLoading: false
};

export const mainSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initTokenRequest: (state) => {
      state.isLoading = true;
    },
    initTokenSuccess: (state) => {
      state.isLoading = false;
    },
    initTokenError: (state) => {
      state.isLoading = false;
    },

    signOutRequest: () => {},
    signOutSuccess: () => {},
    signOutError: () => {}
  }
});

export const { actions, reducer } = mainSlice;
