import { useRef, useState } from "react";
import { toastr } from "react-redux-toastr";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

import styles from "./image-detection-page.module.scss";

const ZoomableContainer = ({ children, zoomScale }) => {
  const containerRef = useRef(null);
  const [transform, setTransform] = useState({ scale: 1, x: 0, y: 0 });

  const handleMouseMove = (event) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const offsetX = (x / rect.width) * 100;
    const offsetY = (y / rect.height) * 100;

    setTransform({
      scale: zoomScale,
      x: offsetX,
      y: offsetY
    });
  };

  const handleMouseLeave = () => {
    setTransform({ scale: 1, x: 0, y: 0 });
  };

  return (
    <div
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%"
      }}
    >
      <div
        style={{
          transform: `scale(${transform.scale}) translate(-${transform.x}%, -${transform.y}%)`,
          transformOrigin: "top left",
          transition: "transform 0.1s ease-out"
        }}
      >
        {children}
      </div>
    </div>
  );
};

const ImageDetection = () => {
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const canvasRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const drawLabels = (detections, imgWidth, imgHeight) => {
  //   const canvas = canvasRef.current;
  //   const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
  //   detections.forEach((det) => {
  //     const padding = 5;
  //     const x1 = det.box.x1 * imgWidth;
  //     const y1 = det.box.y1 * imgHeight;
  //     const x2 = det.box.x2 * imgWidth;
  //     const y2 = det.box.y2 * imgHeight;

  //     const conf = det.confidence;
  //     const label = det.name;

  //     ctx.strokeStyle = "#CCFF00";
  //     ctx.lineWidth = 2;
  //     ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

  //     ctx.textBaseline = "bottom";
  //     const text = `Label: ${label}, Conf: ${conf.toFixed(2)}`;
  //     const width = ctx.measureText(text).width;
  //     ctx.fillStyle = "#18181B";

  //     ctx.fillRect(
  //       x1 - padding + (y1 < 24 ? 8 : 3),
  //       y1 < 24 ? y1 + 2 : y1 - padding - 20,
  //       width + padding * 2,
  //       parseInt(ctx.font, 10) + padding * 2
  //     );

  //     ctx.fillStyle = "white";
  //     ctx.font = "14px Inter";
  //     ctx.fillText(
  //       `Label: ${label}, Conf: ${conf.toFixed(2)}`,
  //       x1 + padding,
  //       y1 - padding - (y1 < 24 ? -24 : 0)
  //     );
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toastr.error("Error", "Please select a file before upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoading(true);

      // const res = await axios.post("http://localhost:8000/upload/", formData);

      // const detections = res.data;

      // if (!detections) throw new Error("No detections");

      const image = new Image();

      image.src = URL.createObjectURL(selectedFile);

      image.onload = () => {
        const canvas = canvasRef.current;
        const maxWidth =
          (window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth) - 32; // 32 is css padding
        const maxHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;

        const ratio = Math.min(
          maxWidth / image.width,
          maxHeight / image.height
        );

        canvas.width = image.width * ratio;
        canvas.height = image.height * ratio;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        // drawLabels(detections, canvas.width, canvas.height);
        setLoading(false);
      };
    } catch (error) {
      setLoading(false);
      toastr.error("Error uploading file:", "error");
    }
  };

  return (
    <PreloaderWrapper loading={isLoading}>
      <div className={styles["image-detection-page"]}>
        <form
          className={styles["image-detection-page__form"]}
          onSubmit={handleSubmit}
        >
          <UploadFiles handleFileChange={handleFileChange} />
          <PrimaryButton type="submit">Upload</PrimaryButton>
        </form>
        <br />
        <ZoomableContainer zoomScale={5}>
          <canvas ref={canvasRef} data-testid="canvas"></canvas>
        </ZoomableContainer>
        {/* TODO remove */}
        {/* <button
          onClick={() => {
            const image = new Image();
            image.src = URL.createObjectURL(selectedFile);
            image.onload = () => {
              const canvas = canvasRef.current;

              const maxWidth =
                (window.innerWidth ||
                  document.documentElement.clientWidth ||
                  document.body.clientWidth) - 32; // 32 is css padding
              const maxHeight =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;

              const ratio = Math.min(
                maxWidth / image.width,
                maxHeight / image.height
              );

              canvas.width = image.width * ratio;
              canvas.height = image.height * ratio;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
              drawLabels(
                [
                  {
                    box: { x1: 0.95, x2: 1, y1: 0.025, y2: 0.6 },
                    confidence: 4.23,
                    name: "TEST"
                  },
                  {
                    box: { x1: 0.5, x2: 0.7, y1: 0.6, y2: 0.8 },
                    confidence: 4.23,
                    name: "TEST2"
                  },
                  {
                    box: { x1: 0.1, x2: 0.3, y1: 0.4, y2: 0.5 },
                    confidence: 4.23,
                    name: "TEST2"
                  }
                ],
                canvas.width,
                canvas.height
              );
            };
          }}
        >
          asdasdasdas
        </button> */}
      </div>
    </PreloaderWrapper>
  );
};

export default ImageDetection;

// /**
//  * For a detailed explanation regarding each configuration property, visit:
//  * https://jestjs.io/docs/configuration
//  */

// /** @type {import('jest').Config} */
// const config = {
//   collectCoverage: true,

//   coverageDirectory: "coverage",

//   coverageProvider: "v8",

//   // moduleDirectories: ["utils", "src"],

//   moduleFileExtensions: [
//     "js",
//     "mjs",
//     "cjs",
//     "jsx",
//     "ts",
//     "tsx",
//     "json",
//     "node"
//   ],

//   // A map from regular expressions to module names or to arrays of module names that allow to stub out resources with a single module
//   moduleNameMapper: {
//     // "\\.(css|scss)$": "identity-obj-proxy"
//   },

//   preset: "ts-jest",

//   transform: {
//     // "\\.(svg)$": "jest-transform-stub"
//     // "^.+\\.[tj]s$": [
//     //   "ts-jest",
//     //   {
//     //     tsconfig: "<rootDir>/tsconfig.json"
//     //   }
//     // ],
//     // "^.+\\.m?js$": "babel-jest"
//   },
//   // transform: {},

//   // An array of regexp pattern strings that are matched against all source file paths, matched files will skip transformation
//   transformIgnorePatterns: [
//     //   "/node_modules/(?!@react-redux-toastr/lib)/",
//     //   "/node_modules/(?!react-redux-toastr/)",
//     "/node_modules/(?!react-leaflet|@react-leaflet)"
//     //   "/react-redux-toastr/"
//   ]
// };

// module.exports = config;
