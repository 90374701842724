import styles from "./secondary.module.scss";

interface PrimaryButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  icon: string;
  children: string;
  onClick?: () => void;
}

const SecondaryButton = ({ type, children, icon, onClick }: PrimaryButtonInterface) => {
  return (
    <button type={type ?? "button"} className={styles["secondary"]} onClick={onClick}>
      <img src={icon} alt={`${children} button icon`} />
      {children}
    </button>
  );
};

export default SecondaryButton;
