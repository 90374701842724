import cn from "classnames";
import BurgerButton from "components/buttons/Burger/Burger";

import logo from "../../assets/logotype.svg";
import ArrawIcon from "../../assets/icons/arrow-left.svg";

import styles from "./header.module.scss";

interface HeaderInterface {
  title?: string;
  isFullFill?: boolean;
  backPath?: string;
}

const Header = ({ isFullFill, backPath }: HeaderInterface) => {
  return (
    <header className={styles["header"]}>
      <div
        className={cn(styles["header__wrapper"], {
          [styles["header__ful-fill-wrapper"]]: isFullFill
        })}
      >
        <BurgerButton />
        {backPath && (
          <a className={styles["header__logo"]} href={backPath}>
            <img src={ArrawIcon} alt="Dropla Logo"></img>
          </a>
        )}
        <a className={styles["header__logo"]} href="/">
          <img src={logo} alt="Dropla Logo"></img>
        </a>
      </div>
    </header>
  );
};

export default Header;
