interface TaskItemLablesInterface {
  dateCreated: string;
  processingTime: string;
  imagesCount: string;
  uuid: string;
  name: string;
  status: string;
  progress: string;
}

interface TaskStatusesInterface {
  queued: number;
  running: number;
  failed: number;
  completed: number;
  canceled: number;
}

interface TaskStatusesTitlesInterface {
  "10": string;
  "20": string;
  "30": string;
  "40": string;
  "50": string;
}

export const taskItemLables: TaskItemLablesInterface = {
  dateCreated: "Created Date",
  processingTime: "Time Elapsed",
  imagesCount: "Images",
  uuid: "UUID",
  name: "Name",
  status: "Status",
  progress: "Progress"
};

export const taskStatusesTitles: TaskStatusesTitlesInterface = {
  "10": "Queued",
  "20": "Running",
  "30": "Failed",
  "40": "Completed",
  "50": "Canceled"
};

export const taskStatuses: TaskStatusesInterface = {
  queued: 10,
  running: 20,
  failed: 30,
  completed: 40,
  canceled: 50
};

export const downloadOrthophotoCoreLink = `http://10.10.90.250:8080/inference/back/odm/orthophotoplan/download`;
