import CommonButton from "components/buttons/CommonButton/CommonButton";

import styles from "./map-action-btns.module.scss";

interface MapActionBtnsInterface {
  buttonsData: any;
}

const MapActionBtns = ({ buttonsData }: MapActionBtnsInterface) => {
  return (
    <div className={styles["map-action-buttons-container"]}>
      {buttonsData.length > 0 &&
        buttonsData.map(({ icon, title, action }) => (
          <CommonButton type="button" icon={icon} onClick={action} key={title}>
            {title}
          </CommonButton>
        ))}
    </div>
  );
};

export default MapActionBtns;
