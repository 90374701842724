import axios from "axios";
import cookie from "js-cookie";
import { has } from "lodash";

interface ApiProviderInterface {
  (baseUrl?: string): providerInterface;
  provider: providerInterface;
}

interface providerInterface {
  client: any;
  clientUpload: any;
  apiURL: string;
  token: string;
}

const ApiProvider = function (baseUrl) {
  const token = cookie.get("dropla_auth");

  if (
    ApiProvider.provider &&
    ApiProvider.provider.apiURL === baseUrl &&
    ApiProvider.provider.token === token
  ) {
    return ApiProvider.provider;
  }

  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json"
    }
  });

  const clientUpload = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  if (token) {
    client.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    client.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });

    clientUpload.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    clientUpload.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });
  } else {
    client.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });
  }

  ApiProvider.provider = {
    client,
    clientUpload,
    apiURL: baseUrl,
    token: token
  };

  return ApiProvider.provider;
} as ApiProviderInterface;

export default ApiProvider;
