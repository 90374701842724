import { MapProvbider } from "../ApiServiceProviders";

export const getLayers = () => {
  return MapProvbider.client
    .get(`/layers`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const getAries = () => {
  return {
    status: 200,
    data: [
      {
        title: "All",
        alias: "All",
        color: "#000000",
        // icon: string,
        isActive: true
      },
      {
        title: "Orange",
        alias: "Orange",
        color: "#FF7A00"
        // icon: string,
        // isActive: boolean,
      },
      {
        title: "Red",
        alias: "Red",
        color: "#FF0000"
        // icon: string,
        // isActive: boolean,
      }
    ]
  };
  // return ApiProvider()
  //   //TODO update path to layers
  //   .client.get(`/layers`)
  //   .then((response) => response)
  //   .catch((error) => error.response);
};

export const getMarkers = ({ params }) => {
  return MapProvbider.client
    .get(`/projects/1/points?limit=100&layer=${params.alias}`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const getPolygons = () => {
  return {
    status: 200,
    data: [
      {
        alias: "All",
        icon: "string",
        color: "#FF0000",
        coordinates: [51.505, -0.091],
        id: "1",
        text: "1%"
      },
      {
        alias: "All",
        icon: "string",
        color: "#FF0000",
        coordinates: [51.505, -0.092],
        id: "2",
        text: "2%"
      },
      {
        alias: "All",
        icon: "string",
        color: "#FF0000",
        coordinates: [51.505, -0.093],
        id: "3",
        text: "3%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.094],
        id: "4",
        text: "4%"
      },
      {
        alias: "Red",
        icon: "string",
        color: "#000000",
        coordinates: [51.505, -0.095],
        id: "5",
        text: "5%"
      },
      {
        alias: "Red",
        icon: "string",
        color: "#000000",
        coordinates: [51.505, -0.096],
        id: "6",
        text: "6%"
      },
      {
        alias: "Red",
        icon: "string",
        color: "#000000",
        coordinates: [51.505, -0.097],
        id: "7",
        text: "7%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.098],
        id: "8",
        text: "8%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.099],
        id: "9",
        text: "9%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.16],
        id: "16",
        text: "16%"
      }
    ]
  };
  // return ApiProvider()
  //   //TODO update path to layers
  //   .client.get(`/layers`)
  //   .then((response) => response)
  //   .catch((error) => error.response);
};

export const getMarkerDetails = async ({ params }) => {
  console.log(
    await MapProvbider.client
      .get(`/projects/1/points/1`)
      .then((response) => response)
      .catch((error) => error.response)
  );
  console.log(
    await MapProvbider.client
      .get(`http://165.22.75.123:1921/mines`)
      .then((response) => response)
      .catch((error) => error.response)
  );

  await new Promise<void>((res) => setTimeout(() => res(), 1000));
  return {
    status: 200,
    data: {
      title: "ПФМ-1 (Пелюсток)",
      id: params.id,
      imgs: ["http//ad"]
    }
  };
  // return ApiProvider()
  //   //TODO update path to layers
  //   .client.get(`/layers`)
  //   .then((response) => response)
  //   .catch((error) => error.response);
};
