import cn from "classnames";
import { ErrorMessage } from "formik";
import Select, { Props } from "react-select";

import styles from "./form-select.module.scss";

interface IOption {
  value: string;
  label: string;
}

interface SelectInterface extends Props {
  value: IOption;
  name?: string;
  label?: string;
  className?: string;
  withFormik?: boolean;
  isSearchable?: boolean;
  onSelectChange?: (item: any, name: string) => void;
}
const FormSelect = ({
  label,
  withFormik,
  name,
  value,
  className,
  isSearchable,
  onSelectChange,
  ...props
}: SelectInterface) => {
  const selectStyles: any = {
    container: (styles) => ({
      ...styles,
      width: "100%"
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: "1px solid #18181B",
      outline: "0",
      borderRadius: "6px",
      margin: "0",
      boxShadow: "none",
      width: "100%",
      minHeight: "42.5px",
      height: "42.5px",
      cursor: "pointer",
      opacity: 1,
      "&:hover": {
        borderColor: "#D6D6D6"
      }
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      minHeight: "27px",
      height: "27px"
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#18181B",
      position: "relative",
      top: "7px"
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none"
    })
  };

  const handleChange = (item: any) => {
    onSelectChange && onSelectChange(item, name);
  };
  return (
    <div className={cn(styles["form-field-wrapper"], className)}>
      {label && <label>{label}</label>}
      <Select
        {...props}
        value={value}
        className={cn("select-container", className)}
        styles={selectStyles}
        classNamePrefix="select"
        name={name}
        onChange={handleChange}
        isSearchable={isSearchable}
        noOptionsMessage={() => "No options"}
      />
      {withFormik && (
        <ErrorMessage name={name} component="div" className="error" />
      )}
    </div>
  );
};

export default FormSelect;
