import { env } from "./env";

export const AUTH_URL = env.REACT_APP_AUTH_URL;
export const GEO_VISION_API_URL = env.REACT_APP_GEO_VISION_API_URL;
export const OVD_API_URL = env.REACT_APP_OVD_API_URL;
export const EXTERNAL_API_URL = env.REACT_APP_EXTERNAL_API_URL;
export const PRIVATE_KEY = env.REACT_APP_PRIVATE_KEY;

//DELETE
export const AREA_COLORS = [
  {
    name: "Medium Hazard",
    color: "rgba(255, 122, 0, 0.8)",
    alias: "orange",
    isActive: false,
    radius: 3
  },
  {
    name: "Hard Hazard",
    color: "rgba(255, 0, 0, 0.8)",
    alias: "red",
    isActive: false,
    radius: 3
  }
];
