import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Auth from "pages/auth";
import ErrorPage from "pages/error";
import ImageDetection from "pages/image-detection";
import Main from "pages/main";
import Map from "pages/map";
import OrthophotoPlanning from "pages/orthophoto-planning";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/404" element={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/auth" element={<Auth />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Main />} />
        <Route path="/image-detection" element={<ImageDetection />} />
        <Route path="/map" element={<Map />} />
        <Route path="/orthophoto-planning" element={<OrthophotoPlanning />} />
      </Route>
    </>
  )
);

function Routes(): JSX.Element {
  return <RouterProvider router={router} />;
}

export default Routes;
