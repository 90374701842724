import L from "leaflet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { RootStateInterface } from "reducer";
import { OrthophotoPlanningStateInterface } from "../../OrthophotoPlanningPage/ducks";
import { MapStateInterface } from "../ducks";

import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import MapActionBtns from "../components/MapActionBtns/MapActionBtns";
import MapLayers from "../components/MapLayers/MapLayers";
import MapMarkerDetails from "../components/MapMarkerDetails/MapMarkerDetails";
import MapMarkers from "../components/MapMarkers/MapMarkers";
import MapPolygons from "../components/MapPolygons/MapPolygons";
import MapZoomBtns from "../components/MapZoomBtns/MapZoomBtns";

import DownloadIcon from "assets/icons/download.svg";
// import FileIcon from "assets/icons/file.svg";
// import FlagIcon from "assets/icons/flag.svg";
// import InfoIcon from "assets/icons/info-circled.svg";
// import PlayIcon from "assets/icons/play.svg";
import ReloadIcon from "assets/icons/reload.svg";
// import ShareIcon from "assets/icons/share.svg";

import axios from "axios";
import styles from "./map-page.module.scss";

const MapPage = () => {
  const [init, setInit] = useState(false);
  const [uploadOrthophotoFile, setUploadOrthophotoFile] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { layers, aries, markers, markerDetails, isLoadingMarkerDetails } =
    useSelector<RootStateInterface, MapStateInterface>((state) => state.map);

  const { isUploadOrthophotoLoading } = useSelector<
    RootStateInterface,
    OrthophotoPlanningStateInterface
  >((state) => state.orthophotoPlanning);

  const selectedLayers = useMemo(
    () => layers.filter((layer) => layer.isActive),
    [layers]
  );
  const selectedAries = useMemo(
    () => aries.filter((aria) => aria.isActive),
    [aries]
  );
  const selectedMarkers = useMemo(
    () =>
      markers.filter(
        (marker) =>
          selectedLayers.find(
            (selectedLayer) => selectedLayer.alias === marker.layer
          ) || marker.layer === "All"
      ),
    [selectedLayers, markers]
  );

  const initData = useCallback(() => {
    dispatch(features.map.actions.fetchLayersRequest());
    dispatch(features.map.actions.fetchAriesRequest());
    setInit(true);
  }, [dispatch]);

  useEffect(() => {
    if (!init) {
      initData();
    }
  }, [init, initData]);

  const handleUploadOrthophotoFile = (event) => {
    setUploadOrthophotoFile(event.target.files[0]);
  };

  const handleUploadOrthophotoFileClick = () => {
    inputRef.current.click();
  };

  const actionsButtonsData = [
    {
      title: uploadOrthophotoFile ? "Reload photos" : "Upload Ortophotoplan",
      icon: uploadOrthophotoFile ? ReloadIcon : DownloadIcon,
      action: handleUploadOrthophotoFileClick
    }
    // {
    //   title: "Download Multispectral",
    //   icon: DownloadIcon,
    //   action: () => console.log("Multispectral pressed")
    // },
    // {
    //   title: "Download Thermal",
    //   icon: DownloadIcon,
    //   action: () => console.log("Download Thermal pressed")
    // },
    // {
    //   title: "Restart analyze",
    //   icon: PlayIcon,
    //   action: () => console.log("Restart analyze pressed")
    // },
    // {
    //   title: "Road for UGV",
    //   icon: FlagIcon,
    //   action: () => console.log("Road for UGV pressed")
    // },
    // {
    //   title: "Create report",
    //   icon: FileIcon,
    //   action: () => console.log("Create report pressed")
    // },
    // {
    //   title: "Info",
    //   icon: InfoIcon,
    //   action: () => console.log("Info pressed")
    // },
    // {
    //   title: "Share",
    //   icon: ShareIcon,
    //   action: () => console.log("Share pressed")
    // }
  ];

  useEffect(() => {
    if (uploadOrthophotoFile) {
      const formData = new FormData();
      formData.append("file", uploadOrthophotoFile);

      dispatch(
        features.orthophotoPlanning.actions.fetchUploadOrthophotoRequest({
          params: formData,
          onSuccess: (res) => {
            dispatch(
              dispatch(features.map.actions.updateMarkersByOrthophoto(res))
            );
          }
        })
      );
    }
  }, [uploadOrthophotoFile]);

  useEffect(() => {
    if (isUploadOrthophotoLoading)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Processing",
            loading: true,
            disableClose: true
          }
        })
      );
    else
      dispatch(
        features.modal.actions.hideModal({
          modalType: "PRELOADER"
        })
      );
  }, [isUploadOrthophotoLoading]);

  //TODO delete
  const testBounds = [
    35.2547273637451, 48.67838098072867, 35.25630639471615, 48.67917852274328
  ];
  const [temporaryToken, setTemporaryToken] = useState("");
  //TODO DELETE important!
  useEffect(() => {
    axios
      .post("https://wdm-proxy.vision.dropla.tech//api/token-auth/", {
        username: "rostuk.mykhalskuy",
        password: "ttUD3dhkMS64AqQ"
      })
      .then((res) => {
        setTemporaryToken(res.data.token);
      });
  }, []);

  return (
    <div className={styles["map-page"]}>
      <MapLayers layers={layers} aries={aries} />
      <MapContainer
        zoom={20}
        zoomControl={false}
        center={{ lat: 48.678689, lng: 35.255448 }}
        className={styles["map"]}
        pmIgnore={false}
        maxZoom={24}
      >
        <TileLayer
          url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          maxNativeZoom={20}
          maxZoom={24}
        />

        <TileLayer
          url={`https://wdm-proxy.vision.dropla.tech//api/projects/146/tasks/a739845a-bf83-4d05-9b2f-d3611b01787e/orthophoto/tiles/{z}/{x}/{y}?jwt=${temporaryToken}`}
          bounds={
            new L.LatLngBounds([
              [testBounds[1], testBounds[0]],
              [testBounds[3], testBounds[2]]
            ])
          }
          maxNativeZoom={26}
          maxZoom={26}
          opacity={1}
          detectRetina={true}
        />
        {selectedMarkers.length > 0 && (
          <MapMarkers
            markers={selectedMarkers}
            isDisabledDefault={
              !layers.find((item) => item.alias === "All")?.isActive
            }
            activeMarkerId={markerDetails?.id}
          />
        )}
        {selectedAries?.length > 0 && selectedMarkers.length > 0 && (
          <MapPolygons
            aries={selectedAries}
            markers={selectedMarkers}
            // isDisabledDefault={
            //   !layers.find((item) => item.alias === "All")?.isActive
            // }
          />
        )}
        <MapActionBtns buttonsData={actionsButtonsData} />
        <UploadFiles
          handleFileChange={handleUploadOrthophotoFile}
          className={styles["map-page__hided-input"]}
          ref={inputRef}
        />
        <MapZoomBtns />
      </MapContainer>
      <MapMarkerDetails
        activeMarker={selectedMarkers.find(
          (marker) => marker.id === markerDetails?.id
        )}
        markerDetails={markerDetails}
        isLoadingMarkerDetails={isLoadingMarkerDetails}
      />
    </div>
  );
};

export default MapPage;
