import cn from "classnames";
import { useCallback, useState } from "react";

import DropdownMenu from "components/DropdownMenu/DropdownMenu";

import ChevronDown from "assets/icons/chevron-down.svg";

import styles from "./select.module.scss";

interface OptionInterface {
  label: string;
  value?: any;
}

interface SelectInterface {
  options: OptionInterface[];
  activeOption?: OptionInterface;
}

const Select = ({ options, activeOption }: SelectInterface) => {
  const [isActive, setIsActive] = useState(false);

  const clickHandler = useCallback(
    (val: boolean) => () => {
      setIsActive(val);
    },
    []
  );

  const optionChoseHandler = useCallback(
    (option) => {
      console.log(option);
      clickHandler(false)();
    },
    [clickHandler]
  );

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["wrapper__label"]}>Sort by</div>
      <div
        className={cn(styles["wrapper__selected"], {
          [styles["wrapper__selected--active"]]: isActive
        })}
        onClick={() =>
          setTimeout(() => {
            clickHandler(true)();
          }, 0)
        }
      >
        {activeOption?.label ?? options[0]?.label}
        <div className={styles["selected__chevron"]}>
          <img src={ChevronDown} alt="chevron" />
        </div>
        <DropdownMenu
          options={options}
          isActive={isActive}
          onClick={optionChoseHandler}
        />
      </div>
    </div>
  );
};
export default Select;
