import cn from "classnames";

import styles from "./map-marker-icon.module.scss";

interface MapMarkerIconInterface {
  color: string;
  probability: number;
  isActive?: boolean;
}

const MapMarkerIcon = ({
  color,
  isActive,
  probability
}: MapMarkerIconInterface) => {
  return (
    <div className={styles["marker"]} data-testid="marker">
      <span
        style={{ borderColor: color, backgroundColor: color }}
        className={cn(styles["marker__text"], styles["marker__mine-name"])}
      >
        TM-62
      </span>
      <span
        style={{ borderColor: color, backgroundColor: color }}
        className={cn(styles["marker__text"], styles["marker__probability"])}
      >
        {Math.round(probability * 100)}%
      </span>
      <div
        className={cn(styles["marker__outer-box"], {
          [styles["marker__hover-efect"]]: isActive
        })}
      >
        <span
          style={{
            borderColor: color,
            borderWidth: "2px",
            borderStyle: "solid"
          }}
          className={styles["marker__square"]}
        />
      </div>
    </div>
  );
};
export default MapMarkerIcon;
